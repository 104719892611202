
@keyframes gearAnimate {
    to{transform: rotate(360deg);}
}

.gearSmartFactory{
    fill:#AAE0FF;
    transform-origin: 1103.8px 1022.3px;
    animation: gearAnimate 5s linear infinite;
}

.gear2{
    fill:#6CE6E6;
    transform-origin: 4019.95px 880.2px;
    animation: gearAnimate 5s linear infinite;
}

@keyframes caixa1Animate{
    0%{transform: translateX(0);}
    25%{transform: translateX(60px);}
    50%{transform: translateX(0);}
    75%{transform: translateX(-60px);}
    100%{transform: translateX(0);}
}

.caixa1SmartFactory{
    fill: #E68D45;
    animation: caixa1Animate 4s linear infinite;
}

.sombraCaixaSmartFactory{   
    fill: #FDB477;
    animation: caixa1Animate 4s linear infinite;
}

.faixaCaixaSmartFactory{
    fill:#FFFFFF;
    animation: caixa1Animate 4s linear infinite;
}

.dadosCaixaSmartFactory{
    fill:#E68D45;
    animation: caixa1Animate 4s linear infinite;
}

@keyframes signaBar1Animate {
    0%{opacity: 0;}
    20%{opacity: 1;}
    70%{opacity: 1;}
    90%{opacity: 0;}

}

@keyframes signaBar2Animate {
    5%{opacity: 0;}
    25%{opacity: 1;}
    75%{opacity: 1;}
    95%{opacity: 0;}

}
@keyframes signaBar3Animate {
    10%{opacity: 0;}
    30%{opacity: 1;}
    80%{opacity: 1;}
    100%{opacity: 0;}

}

.signaBar1{
    animation: signaBar1Animate 4s linear infinite;
}

.signaBar2{
    animation: signaBar2Animate 4s linear infinite;
}
.signaBar3{
    animation: signaBar3Animate 4s linear infinite;
}

.signaBar3, .signaBar2, .signaBar1{
    fill:#AAE0FF;
}

@keyframes graph1BarAnimate {
    0%{height: 0px;
        transform: translateY(56.1px);}
    50%{height: 56.1px;
        transform: translateY(0px);}
    80%{height: 56.1px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(56.1px);}
}

@keyframes graph2BarAnimate {
    0%{height: 0px;
        transform: translateY(68.9px);}
    50%{height: 68.9px;
        transform: translateY(0px);}
    80%{height: 68.9px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(68.9px);}
}

@keyframes graph3BarAnimate {
    0%{height: 0px;
        transform: translateY(94.8px);}
    50%{height: 94.8px;
        transform: translateY(0px);}
    80%{height: 94.8px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(94.8px);}
}

@keyframes graph4BarAnimate {
    0%{height: 0px;
        transform: translateY(112.9px);}
    50%{height: 112.9px;
        transform: translateY(0px);}
    80%{height: 112.9px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(112.9px);}
}

@keyframes graph5BarAnimate {
    0%{height: 0px;
        transform: translateY(152px);}
    50%{height: 152px;
        transform: translateY(0px);}
    80%{height: 152px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(152px);}
}

.graphBar5SmartFactory{
    fill:#FDFDFF;
    animation: graph5BarAnimate 5s linear infinite;
}

.graphBar4SmartFactory{
    fill:#FDFDFF;
    animation: graph4BarAnimate 5s linear infinite;
}

.graphBar3SmartFactory{
    fill:#FDFDFF;
    animation: graph3BarAnimate 5s linear infinite;
}

.graphBar2SmartFactory{
    fill:#FDFDFF;
    animation: graph2BarAnimate 5s linear infinite;
}

.graphBar1SmartFactory{
    fill:#FDFDFF;
    animation: graph1BarAnimate 5s linear infinite;
}

@keyframes arm2Animate {
    0%{transform: translateY(0px);}
    50%{transform: translateY(15px);}
    100%{transform: translateY(0px);}
}

.armBar2{
    fill: #493F7A;
    transform-origin: 3369.55px 908.85px;
    animation: arm2Animate 4s linear infinite;
}


.armBar2LightBlue{
    fill:#48C6C6;
    transform-origin: 3369.55px 908.85px;
    animation: arm2Animate 4s linear infinite;
}

@keyframes armAnimate {
    0%{transform: rotate(0deg);}
    25%{transform: rotate(20deg);}
    50%{transform: rotate(0deg);}
    75%{transform: rotate(-20deg);}
}

.barArm{
    fill:#453B73;
    transform-origin: 1940.11px 757.4px;
    animation: armAnimate 5s linear infinite;
}

.boxArm{
    fill:#48C6C6;
    transform-origin: 1940.11px 757.4px;
    animation: armAnimate 5s linear infinite;
}

.boxLineArm{
    fill:#453B73;
    transform-origin: 1940.11px 757.4px;
    animation: armAnimate 5s linear infinite;
}

.handArm{
    fill:#48C6C6;
    transform-origin: 1940.11px 757.4px;
    animation: armAnimate 5s linear infinite;
}

.screwHand{
    fill:#453B73;
    transform-origin: 1940.11px 757.4px;
    animation: armAnimate 5s linear infinite;
}

.tweezersArm{
    fill:#453B73;
    transform-origin: 1940.11px 757.4px;
    animation: armAnimate 5s linear infinite;
}

@keyframes graph2Bar1Animate {
    0%{height: 0px;
        transform: translateY(56.2px);}
    50%{height: 56.2px;
        transform: translateY(0px);}
    80%{height: 56.2px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(56.2px);}
}

@keyframes graph2Bar2Animate {
    0%{height: 0px;
        transform: translateY(64.6px);}
    50%{height: 64.6px;
        transform: translateY(0px);}
    80%{height: 64.6px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(64.6px);}
}

@keyframes graph2Bar3Animate {
    0%{height: 0px;
        transform: translateY(81.8px);}
    50%{height: 81.8px;
        transform: translateY(0px);}
    80%{height: 81.8px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(81.8px);}
}

@keyframes graph2Bar4Animate {
    0%{height: 0px;
        transform: translateY(93.8px);}
    50%{height: 93.8px;
        transform: translateY(0px);}
    80%{height: 93.8px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(93.8px);}
}

@keyframes graph2Bar5Animate {
    0%{height: 0px;
        transform: translateY(119.7px);}
    50%{height: 119.7px;
        transform: translateY(0px);}
    80%{height: 119.7px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(119.7px);}
}

.graph2Bar5{
    fill:#FDFDFF;
    animation: graph2Bar5Animate 5s linear infinite;
}

.graph2Bar4{
    fill:#FDFDFF;
    animation: graph2Bar4Animate 5s linear infinite;
}

.graph2Bar3{
    fill:#FDFDFF;
    animation: graph2Bar3Animate 5s linear infinite;
}

.graph2Bar2{
    fill:#FDFDFF;
    animation: graph2Bar2Animate 5s linear infinite;
}

.graph2Bar1{
    fill:#FDFDFF;
    animation: graph2Bar1Animate 5s linear infinite;
}

@keyframes loadingSmartFactoryAnimate {
    0%{width: 0px;
        transform: translateX(0px);}
    25%{width: 605px;
        transform: translateX(0px);}
    50%{width: 0px;
        transform: translateX(605px);}
    75%{width: 605px;
        transform: translateX(0px)}
    100%{
        width: 0px;
    }
}

.loadingBarSmartFactory{
    fill:#4AC4D9;
    animation: loadingSmartFactoryAnimate 8s linear infinite;
}

            .st0{fill:#CCF3FF;}
            .st1{fill:#AAE0FF;}
            .st2{fill:#48C6AA;}
            .st3{fill:#FFFFFF;}
            .st4{fill:#6CE6E6;}
            .st5{fill:#75CDFF;}
            .st6{fill:#E68D45;}
            .st7{fill:#FDB477;}
            .st8{fill:#5B4E99;}
            .st9{fill:#61C6FF;}
            .st10{fill:#E6F9FF;}
            .st11{fill:#42377A;}
            .st12{fill:#332C57;}
            .st13{fill:#48C6C6;}
            .st14{fill:#241F3C;}
            .st15{fill:#312A52;}
            .st16{fill:#299999;}
            .st17{fill:#82EDED;}
            .st18{fill:#A6FFFF;}
            .st19{fill:#453B73;}
            .st20{fill:#1F9999;}
            .st21{fill:#6369CC;}
            .st22{fill:#3F366B;}
            .st23{fill:#FDFDFF;}
            .st24{fill:#493F7A;}
            .st25{fill:#6CD2FF;}
            .st26{fill:#66C9ED;}
            .st28{fill:#4395BA;}
            .st29{fill:#4D93FF;}
            .st30{fill:#3565B0;}
            .st31{fill:#4AC4D9;}
            .st32{fill:#CFFAFA;}
            .st33{fill:#84F0F0;}
            .st34{fill:#F7A594;}
            .st35{fill:#E6836F;}
            .st36{fill:#002E73;}
            .st37{fill:#231F20;}
            .st38{fill:#73382A;}
            .st39{fill:#FFB655;}
            .st40{fill:#FFEE73;}
            .st41{fill:#FFDD59;}
            .st42{fill:#F7C4B9;}
            .st43{fill:#5D4F99;}
            .st44{fill:#0E0E0E;}
            .st45{fill:#FFA88F;}
            .st46{fill:#0D0D0D;}
            .st47{fill:#FC8968;}
            .st48{fill:#80B1FF;}
            .st49{fill:#0D192B;}
            .st50{fill:#F7B28A;}
            .st51{fill:#B3D7FF;}
            .st52{fill:#FFB900;}
            .st53{fill:#D69147;}
            .st54{fill:#854500;}
            .st55{fill:#003C40;}
            .st56{fill:#D9790D;}
            .st57{fill:#FF9117;}
            .st58{fill:#1E62CC;}
            .st59{fill:#092E66;}
            .st60{fill:#051733;}
            .st61{fill:#73CCFF;}
            .st62{fill:#CC7767;}
            .st63{fill:#404040;}
            .st64{fill:#D97661;}
            .st65{fill:#CF6D53;}
            .st66{fill:#9E533F;}
            .st67{fill:#A85900;}
            .st68{fill:#7D73AB;}
            .st69{fill:#ED5A24;}
            .st70{fill:#F77854;}
            .st71{fill:#F7A58E;}
            .st72{fill:#E89A00;}
            .st73{fill:#4D4D4D;}
            .st74{fill:#CCCCCC;}