

.gear{
    fill:#AAE0FF;
}

@keyframes caixa1Animate{
    0%{transform: translateX(0);}
    25%{transform: translateX(-100px);}
    50%{transform: translateX(0);}
    75%{transform: translateX(100px);}
    100%{transform: translateX(0);}
}

.caixa1{
    fill: #E68D45;
    animation: caixa1Animate 6s linear infinite;
}

.sombraCaixa{   
    fill: #FDB477;
    animation: caixa1Animate 6s linear infinite;
}

.faixaCaixa{
    fill:#FFFFFF;
    animation: caixa1Animate 6s linear infinite;
}

.dadosCaixa{
    fill:#E68D45;
    animation: caixa1Animate 6s linear infinite;
}

@keyframes signaBar1Animate {
    0%{opacity: 0;}
    20%{opacity: 1;}
    70%{opacity: 1;}
    90%{opacity: 0;}

}

@keyframes signaBar2Animate {
    5%{opacity: 0;}
    25%{opacity: 1;}
    75%{opacity: 1;}
    95%{opacity: 0;}

}
@keyframes signaBar3Animate {
    10%{opacity: 0;}
    30%{opacity: 1;}
    80%{opacity: 1;}
    100%{opacity: 0;}

}

.signalBar1{
    animation: signaBar1Animate 4s linear infinite;
}

.signalBar2{
    animation: signaBar2Animate 4s linear infinite;
}
.signalBar3{
    animation: signaBar3Animate 4s linear infinite;
}

.signalBar3, .signalBar2, .signalBar1{
    fill:#A3EEFA;
}


@keyframes graphBar1Animate {
    0%{height: 0px;
        transform: translateY(38.8px);}
    50%{height: 38.8px;
        transform: translateY(0px);}
    80%{height: 38.8px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(38.8px);}
}

@keyframes graphBar2Animate {
    0%{height: 0px;
        transform: translateY(47.6px);}
    50%{height: 47.6px;
        transform: translateY(0px);}
    80%{height: 47.6px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(47.6px);}
}

@keyframes graphBar3Animate {
    0%{height: 0px;
        transform: translateY(65.5px);}
    50%{height: 65.5px;
        transform: translateY(0px);}
    80%{height: 65.5px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(65.5px);}
}

@keyframes graphBar4Animate {
    0%{height: 0px;
        transform: translateY(78.1px);}
    50%{height: 78.1px;
        transform: translateY(0px);}
    80%{height: 78.1px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(78.1px);}
}

@keyframes graphBar5Animate {
    0%{height: 0px;
        transform: translateY(105.1px);}
    50%{height: 105.1px;
        transform: translateY(0px);}
    80%{height: 105.1px;
        transform: translateY(0px);}
    100%{height: 0px;
        transform: translateY(105.1px);}
}

.graphBar5{
    fill:#FDFDFF;
    animation: graphBar5Animate 5s linear infinite;

}

.graphBar4{
    fill:#FDFDFF;
    animation: graphBar4Animate 5s linear infinite;

}

.graphBar3{
    fill:#FDFDFF;
    animation: graphBar3Animate 5s linear infinite;

}

.graphBar2{
    fill:#FDFDFF;
    animation: graphBar2Animate 5s linear infinite;

}
.graphBar1{
    fill:#FDFDFF;
    animation: graphBar1Animate 5s linear infinite;

}

@keyframes loadingAnimate {
    0%{width: 0px;
        transform: translateX(0px);}
    25%{width: 420px;
        transform: translateX(0px);}
    50%{width: 0px;
        transform: translateX(420px);}
    75%{width: 420px;
        transform: translateX(0px)}
    100%{
        width: 0px;
    }
}

.loadingBar{
    fill:#48C6C6;
    animation: loadingAnimate 8s linear infinite;
}

@keyframes pointerAnimate {
    0%{transform: rotate(0deg);}
    70%{transform: rotate(70deg);}
}

.pointer{
    fill:#268080;
    transform-origin: 717.45px 1142.75px;
    animation: pointerAnimate 3s linear infinite;
}

@keyframes pointer2Animate {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(-20deg);}
}

.pointer2{
    fill:#268080;
    transform-origin: 925.89px 1161.65px;
    animation: pointer2Animate 2s linear infinite;
}

@keyframes gearAnimate {
    to{transform: rotate(360deg);}
}

.gear{
    fill:#AAE0FF;
    transform-origin: 3215.7px 1061.25px;
    animation: gearAnimate 5s linear infinite;
}

@keyframes checkAnimate {
}

.checkCircle{
    fill:#48C6AA;
    animation: checkAnimate 10s linear infinite;
}

@keyframes column2line2Animate {
    0%{opacity: 0;}
    19%{opacity: 0;}
    20%{opacity: 1;}
    70%{opacity: 1;}
    71%{opacity: 0;}
    100%{opacity: 0;}
}

.column2line2{
    fill:#82EDED;
    animation: column2line2Animate 4s linear infinite;
}

.column4line3{
    fill:#82EDED;
    animation: column2line4Animate 4s linear infinite;
}

@keyframes column2line3Animate {
    0%{opacity: 0;}
    39%{opacity: 0;}
    40%{opacity: 1;}
    90%{opacity: 1;}
    91%{opacity: 0;}
    100%{opacity: 0;}
}

.column2line3{
    fill:#82EDED;
    animation: column2line3Animate 4s linear infinite;
}

.column4line3{
    fill:#82EDED;
    animation: column2line4Animate 4s linear infinite;
}

@keyframes column2line4Animate {
    0%{opacity: 0;}
    59%{opacity: 0;}
    60%{opacity: 1;}
    80%{opacity: 1;}
    81%{opacity: 0;}
    100%{opacity: 0;}
}

.column2line4{
    fill:#82EDED;
    animation: column2line4Animate 4s linear infinite;
}

.in0{fill:#E3FAFA;}
.in1{fill:#A3EEFA;}
.in2{fill:#48C6C6;}
.in3{fill:#312A52;}
.in4{fill:#FFFFFF;}
.in5{fill:#CFFAFA;}
.in6{fill:#FDFDFF;}
.in7{fill:#241F3C;}
.in8{fill:#3CA6A6;}
.in9{fill:#41B3B3;}
.in10{fill:#2A7373;}
.in11{fill:#E68D45;}
.in12{fill:#FDB477;}
.in13{fill:#3D3466;}
.in14{fill:#338C8C;}
.in15{fill:#82EDED;}
.in16{fill:#268080;}
.in17{fill:#48C6AA;}