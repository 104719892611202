*{
    margin: 0;
    padding: 0;
    background: none;
}
::-webkit-scrollbar{
    width: 8px;
    background-color: #E5E5E5;
}


::-webkit-scrollbar-thumb{
background-color: #cacaca;
border-radius: 5px;
}
::-webkit-scrollbar-track{
    border-radius: 5px;
    background: #919191;
}